import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  toggle() {
    this.inputTarget.click();
  }

  close(event) {
    // if (!this.element.contains(event.target)) {
    //   this.optionsTarget.classList.add('hidden')
    // }
  }

  connect() {
    document.addEventListener('click', this.close.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.close.bind(this))
  }
}
